<template>
    <modal>
        <template v-slot:title>{{ $t('Add') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Point_name') }}</h4>
                        <input type="text" class="form-control" v-model="form.name" >
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('type') }}</h4>
                        <Multiselect
                            v-model="form.type"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="listType"
                        />
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Required') }}</h4>
                        <Multiselect
                            v-model="form.required"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="listRequired"
                        />
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('description') }}</h4>
                        <input type="text" class="form-control" v-model="form.comment" >
                    </div>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button>
            <button type="button" class="btn btn-primary" v-on:click="add">{{ $t('Add') }}</button>
        </template>
    </modal>
 </template>
 
<script>
import modal from '@/components/modal-small'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { ApiDocs } from '@/API.js';
import { storeS } from '@/store.js' 
 
 let apiServe = new ApiDocs();
 
export default ({
    data(){
        return {
            form: {
                name: "",
                type: "",
                required: 1,
                comment: ""
            },
            listRequired: [
                { label: this.$t('Required'), value: '1' },
                { label: this.$t('NotRequired'), value: '0' }
            ],
            listType: [
                { label: 'array', value: 'array' },
                { label: 'boolean', value: 'boolean' },
                { label: 'int', value: 'int' },
                { label: 'number', value: 'number' },
                { label: 'object', value: 'object' },
                { label: 'string', value: 'string' },
                { label: 'null', value: 'null' },
            ]
        }
    },
    components: { modal, Multiselect },
    created(){
    },
    methods: {
        add() {
            if (!this.form.name) {
                //перевіряємо чи вказана назва
                this.$toast.error(this.$t('EnterNameTicket'));
            }
            if (!this.form.type) {
                //перевіряємо чи вказаний тип
                this.$toast.error(this.$t('EnterType'));
            }
            if (!this.form.required) {
                //перевіряємо чи вказаний стан атрибуту
                this.$toast.error(this.$t('EnterRequired'));
            }
            if (!this.form.comment) {
                //перевіряємо чи вказаний опис
                this.$toast.error(this.$t('EnterDesc'));
            }

            if(this.form.name && this.form.type && this.form.required && this.form.comment){
                apiServe.addAttribute(this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('сreatedT'));
                        this.$emit('close')
                        this.eventBus.emit('saveAttr', true)
                    } else {
                        this.$toast.error(this.$t("error") + " #AddAttr")
                    }
                })
            }
        },   
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
 })
</script>