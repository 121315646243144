<template>
    <PageHeader :title="title" />

    <tablecustom 
        @search="searchB" 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="true"
        :createdform="true" 
        @create="showCategoryBox = true" 
        @open="open" 
        @remove="remove" 
        @changeFilter="changeFilter"  
        @getdata="getdata"
        @changelimit="changelimit"
    />

    <viewApibox 
        v-if="showModalview == true" 
        @close="showModalview = false" 
        :objCard="this.objCard" 
        :ifPrevopen="ifPrevopen" 
        :ifNextopen="ifNextopen" 
        @prevOpen="prevOpen" 
        @nextOpen="nextOpen" 
        @open="open"
    ></viewApibox>

    <createApiBox 
        v-if="showCategoryBox == true" 
        @close="showCategoryBox = false"
    ></createApiBox> 

</template>

<script>
import PageHeader from "@/components/page-header";
import viewApibox from './view'
import createApiBox from './create'
import tablecustom from '@/components/globaltable/index';
import { ApiDocs } from '@/API.js';
import { storeS } from '@/store.js'

let apiServe = new ApiDocs();

export default {
    data() {
        return {
            title: this.$t('ApiDocs'),
            rows: [],
            objCard: "",
            showModalview: false,
            showCategoryBox: false,
            ifNextopen: "",
            ifPrevopen: "",
            objPages: "",
            dataParams: {
                status: false,
                page: "changelog"
            },
            objParams:{
                page: '1',
                pagelimit: '10',
                search: ''
            },
            columns: [
                {
                    name: 'ID',
                    text: "apiId",
                    align: "left",
                    width: 100,
                    status: true,
                },
                {
                    name: this.$t("Point_name"),
                    text: "name",
                    align: "left",
                    width: 300,
                    status: true,
                },
                {
                    name: this.$t("type"),
                    text: "type",
                    align: "left",
                    width: 300,
                    status: true,
                },
                {
                    name: 'URL',
                    text: "url",
                    align: "left",
                    width: 300,
                    status: false
                }
            ],
        };
    },
    components: {
        PageHeader,
        createApiBox,
        tablecustom,
        viewApibox
        // viewbox,
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(page){
            this.objParams.page = page != undefined ? page : '1';
            apiServe.getApis(this.objParams).then(result => {
                if(result.status === 'done') {
                    this.rows = result.data.items;
                    this.objPages = result.data;
                } else {
                    this.$toast.error(this.$t('error') + ` #380`);
                }
            })
        },
        changelimit: function(limit){
            this.objParams.pagelimit = limit;
            this.getdata();
        },
        open(e){
            var id = e.apiId ? e.apiId : e;
            this.ifPrevopen = id == this.rows[0].apiId ? false : true;
            this.ifNextopen = id == this.rows[this.rows.length-1].apiId ? false : true;

            apiServe.getApi(id).then(result =>{
                if(result.status == 'done'){
                    this.objCard = result.data
                    this.showModalview = true
                } else {
                    this.$toast.error(this.$t("error"))
                }
            })
        },
        prevOpen(e){
            var current_id = e;
            var prev_id = ""; 

            for(var value in this.rows){
                if(this.rows[value].apiId == current_id){
                    prev_id = this.rows[Number(value)-Number('1')].apiId;
                }
            }
            this.open(prev_id);
        },
        nextOpen(e){
            var current_id = e;
            var next_id = "";

            for(var value in this.rows){
                if(this.rows[value].apiId == current_id){
                    next_id = this.rows[Number(value)+Number('1')].apiId;
                }
            }
            this.open(next_id);
        },
        remove: function(e){
            apiServe.deleteUpdate(e.id).then(res => {
                if(res){
                    this.$toast.success(this.$t('Removed'));
                    this.getdata();
                }
            })
        },
        searchB(e){
            this.objParams.search = e
            this.getdata();
        },
    },
    computed: {
        perms() {
            return storeS.perms
        }
    },
    mounted() {
        this.eventBus.on('saveApi', (status) => {
            if(status == true) {
                this.getdata();
            }
        })
    },
};
</script>