<template>
    <modal :stylebody="'var(--vz-body-bg)'">
        <template v-slot:title>{{ $t('Add') }}</template>
        <template v-slot:head-bottom>
            <div class="dropdown">
                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                    <button class="dropdown-item" disabled>{{ $t('toEditAp') }}</button>
                    <button class="dropdown-item" @click="toCopy(form)">{{ $t('Duplicate') }}</button>
                </div>
            </div>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Point_name') }}</h4>
                        <input type="text" class="form-control" v-model="form.name" :disabled="statusEdit == false" >
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('type') }}</h4>
                        <Multiselect
                            v-model="form.type"
                            :close-on-select="false"
                            :searchable="true"
                            :create-option="false"
                            :options="listType"
                            :disabled="statusEdit == false"
                        />
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">URL</h4>
                        <input type="text" class="form-control" v-model="form.url" :disabled="statusEdit == false" >
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('project') }}</h4>
                        <Multiselect
                            v-model="form.apiCategoryId"
                            :close-on-select="false"
                            :searchable="true"
                            :create-option="false"
                            :options="category"
                            :disabled="statusEdit == false"
                        />
                    </div>
                </b-col>
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('attributes') }}</h4>
                        <tablecustom 
                            :dataParams="dataParams"
                            :objParams="objParams"
                            :pages="objPages"
                            :showpag="true"
                            :columns="columns" 
                            :rows="rows"
                            :checked="true" 
                            :checkedItems="form.attributes"
                            @getdata="getAttr"
                            @check="check"
                            @changelimit="changelimit"
                            @search="searchB"
                        />
                    </div>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button>
            <button type="button" class="btn btn-danger" @click="remove()">{{ $t('Delete') }}</button>
            <button type="button" class="btn btn-success" @click="statusEdit == false ? statusEdit = true : edit()">{{ statusEdit == false ? $t('Edit') : $t('save') }}</button>
        </template>
    </modal>
 </template>
 
<script>
import modal from '@/components/modal'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import tablecustom from '@/components/globaltable/index';
import { ApiDocs } from '@/API.js';
import { storeS } from '@/store.js' 
 
 let apiServe = new ApiDocs();
 
export default ({
    props: ['objCard'],
    data(){
        return {
            form: {
                name: "",
                url: "",
                type: "",
                apiCategoryId: '',
                attributes: []
            },
            statusEdit: false,
            listType: [
                { label: 'GET', value: 'get' },
                { label: 'DELETE', value: 'delete' },
                { label: 'POST', value: 'post' },
                { label: 'PUT', value: 'put' }
            ],
            category: [],
            attr: [],
            categParams: {
                page: '1',
                pagelimit: '50',
            },
            objPages: "",
            dataParams: {
                status: false,
                page: "attr"
            },
            attrParams: {
                page: '1',
                pagelimit: '15',
                search: ''
            },
            rows: [],
            columns: [
                {
                    name: 'ID',
                    text: "attributeId",
                    align: "left",
                    status: false,
                },
                {
                    name: this.$t("Point_name"),
                    text: "name",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("type"),
                    text: "type",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("description"),
                    text: "comment",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Required"),
                    text: "required",
                    align: "left",
                    status: true,
                },
            ]
        }
    },
    components: { modal, Multiselect, tablecustom },
    created(){
        this.form = this.objCard
        this.getCategory();
        this.getAttr();

        this.eventKey.off('dublicate')
        this.eventKey.on('dublicate', () => {
            this.toCopy(this.form);
        })
    },
    methods: {
        toCopy(e){
            apiServe.createApi(e).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Copy'));
                    this.$emit('close')
                    this.$emit('open', result.data)
                    // this.eventBus.emit('saveApi', true)
                }
            })
        },
        edit() {
            apiServe.editApi(this.form.apiId, this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('сreatedT'));
                    this.$emit('close')
                    this.eventBus.emit('saveApi', true)
                } else {
                    this.$toast.error(this.$t("error"))
                }
            })
        },  
        remove() {
            apiServe.deleteApi(this.form.apiId).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Removed'));
                    this.$emit('close')
                    this.eventBus.emit('saveApi', true)
                } else {
                    this.$toast.error(this.$t("error"))
                }
            })
        },
        check(options){
            this.form.attributes = options
        },
        getCategory(){
            apiServe.getApiCategories(this.categParams).then(result => {
                if(result.status == 'done'){
                    var items = result.data.items;
                    for(var item in items){
                        this.category.push(
                            {
                                label: items[item].categoryName,
                                value: items[item].apiCategoryId
                            }
                        )
                    }
                } else {
                    this.$toast.error(this.$t("error"))
                }
            })
        },
        getAttr(page){
            this.attrParams.page = page != undefined ? page : '1';
            apiServe.getAttributes(this.attrParams).then(result => {
                if(result.status == 'done'){
                    this.rows = result.data.items;
                    this.objPages = result.data;
                } else {
                    this.$toast.error(this.$t("error"))
                }
            })
        },
        changelimit: function(limit){
            this.attrParams.pagelimit = limit;
            this.getAttr();
        },
        searchB(e){
            this.attrParams.search = e
            this.getAttr();
        },
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
 })
</script>