<template>
    <modal :stylebody="'var(--vz-body-bg)'">
        <template v-slot:title>{{ $t('Add') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Point_name') }}</h4>
                        <input type="text" class="form-control" v-model="form.name" >
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('type') }}</h4>
                        <Multiselect
                            v-model="form.type"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="listType"
                        />
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">URL</h4>
                        <input type="text" class="form-control" v-model="form.url" >
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('project') }}</h4>
                        <Multiselect
                            v-model="form.apiCategoryId"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="category"
                        />
                    </div>
                </b-col>
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('attributes') }}</h4>
                        <tablecustom 
                            :dataParams="dataParams"
                            :objParams="categParams"
                            @search="searchB" 
                            :pages="objPages"
                            :showpag="true"
                            :columns="columns" 
                            :rows="rows"
                            :checked="true" 
                            @check="check"
                            :createdform="true"
                            @create="showAttrBox = true" 
                            @getdata="getAttr"
                            @changelimit="changelimit"
                        />
                    </div>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>
            <!-- <button class="btn link-success fw-medium" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button> -->
            <button type="button" class="btn btn-success" v-on:click="add">{{ $t('Add') }}</button>
        </template>
    </modal>

    <createAttributeBox 
        v-if="showAttrBox == true" 
        @close="showAttrBox = false"
    ></createAttributeBox> 

 </template>
 
<script>
import modal from '@/components/modal'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import tablecustom from '@/components/globaltable/index';
import createAttributeBox from '../attributesdocs/create'
import { ApiDocs } from '@/API.js';
import { storeS } from '@/store.js' 
 
 let apiServe = new ApiDocs();
 
export default ({
    data(){
        return {
            showAttrBox: false,
            form: {
                name: "",
                url: "",
                type: "",
                apiCategoryId: '',
                attributes: []
            },
            listType: [
                { label: 'GET', value: 'get' },
                { label: 'DELETE', value: 'delete' },
                { label: 'POST', value: 'post' },
                { label: 'PUT', value: 'put' }
            ],
            category: [],
            attr: [],
            objPages: "",
            dataParams: {
                status: false,
                page: "attr"
            },
            categParams: {
                page: '1',
                pagelimit: '15',
                search: ''
            },
            rows: [],
            columns: [
                {
                    name: 'ID',
                    text: "attributeId",
                    align: "left",
                    status: false
                },
                {
                    name: this.$t("Point_name"),
                    text: "name",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t("type"),
                    text: "type",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t("description"),
                    text: "comment",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t("Required"),
                    text: "required",
                    align: "left",
                    status: true
                },
            ]
        }
    },
    components: { modal, Multiselect, tablecustom, createAttributeBox },
    created(){
        this.getCategory();
        this.getAttr();
    },
    methods: {
        add() {
            if (!this.form.name) {
                //перевіряємо чи вказана назва
                this.$toast.error(this.$t('EnterNameTicket'));
            }
            if (!this.form.type) {
                //перевіряємо чи вказаний тип
                this.$toast.error(this.$t('EnterType'));
            }
            if (!this.form.url) {
                //перевіряємо чи вказане посилання
                this.$toast.error(this.$t('EnterURL'));
            }
            if (!this.form.apiCategoryId) {
                //перевіряємо чи вказана категорія
                this.$toast.error(this.$t('EnterProject'));
            }
            if(this.form.name && this.form.type && this.form.url && this.form.apiCategoryId){
                apiServe.createApi(this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('сreatedT'));
                        this.$emit('close')
                        this.eventBus.emit('saveApi', true)
                    } else {
                        this.$toast.error(this.$t("error"))
                    }
                })
            }
        },  
        check(options){
            this.form.attributes = options
        },
        getCategory(){
            apiServe.getApiCategories(this.categParams).then(result => {
                if(result.status == 'done'){
                    var items = result.data.items;
                    for(var item in items){
                        this.category.push(
                            {
                                label: items[item].categoryName,
                                value: items[item].apiCategoryId
                            }
                        )
                    }
                } else {
                    this.$toast.error(this.$t("error"))
                }
            })
        },
        getAttr(page){
            this.categParams.page = page != undefined ? page : '1';
            apiServe.getAttributes(this.categParams).then(result => {
                if(result.status == 'done'){
                    this.rows = result.data.items;
                    this.objPages = result.data;
                } else {
                    this.$toast.error(this.$t("error"))
                }
            })
        },
        changelimit: function(limit){
            this.categParams.pagelimit = limit;
            this.getAttr();
        },
        searchB(e){
            this.categParams.search = e
            this.getAttr();
        },
    },
    mounted(){
        this.eventBus.on('saveAttr', (status) => {
            if(status == true) {
                this.getAttr();
            }
        })
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
 })
</script>